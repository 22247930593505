var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "未付明细",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": _vm.hideFilterView ? '展开窗口' : '折叠窗口'
    },
    on: {
      "click-right": _vm.handleHideFilterView
    }
  }), _c('div', {
    staticClass: "fixed-container",
    style: _vm.hideFilterView ? 'display: none' : 'display: block'
  }, [_c('van-cell', {
    attrs: {
      "title": "开始日期",
      "value": _vm.listQuery.begin_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleChooseDate('begin');
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "结束日期",
      "value": _vm.listQuery.end_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleChooseDate('end');
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "来货金额",
      "value-class": "cell-value",
      "value": '￥' + _vm.totalAmount,
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "退货金额",
      "value-class": "cell-value",
      "value": '￥' + _vm.totalReturnAmount,
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "未付金额(含退货)",
      "value-class": "cell-value",
      "value": '￥' + _vm.totalNopayAmount,
      "center": ""
    }
  })], 1), _c('van-list', {
    ref: "list",
    class: 'list' + (_vm.hideFilterView ? ' hide-filter' : ''),
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-checkbox-group', {
    model: {
      value: _vm.paymentData.nums,
      callback: function callback($$v) {
        _vm.$set(_vm.paymentData, "nums", $$v);
      },
      expression: "paymentData.nums"
    }
  }, _vm._l(_vm.list, function (acceptOrder, index) {
    return _c('div', {
      key: acceptOrder.id,
      staticClass: "cell-container"
    }, [_c('div', {
      staticClass: "cell",
      on: {
        "click": function click($event) {
          return _vm.handleCellCheck(index);
        }
      }
    }, [!acceptOrder.is_pay ? _c('div', {
      staticClass: "cell-check"
    }, [_c('van-checkbox', {
      ref: 'checkbox',
      refInFor: true,
      attrs: {
        "name": acceptOrder.num
      }
    })], 1) : _vm._e(), _c('div', {
      staticClass: "cell-content"
    }, [_c('van-cell', {
      attrs: {
        "title": "供应商",
        "value": acceptOrder.supplier.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "门店",
        "value": acceptOrder.store.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "制单人",
        "value": acceptOrder.creator.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": acceptOrder.type === 1 ? '入库金额' : '退货金额',
        "value": '￥' + acceptOrder.amount,
        "value-class": "cell-value"
      }
    }), _c('van-cell', {
      attrs: {
        "title": acceptOrder.type === 1 ? '入库日期' : '退货日期',
        "value": acceptOrder.date
      }
    }), acceptOrder.is_pay ? _c('van-cell', {
      attrs: {
        "title": "操作时间",
        "value": acceptOrder.pay_datetime
      }
    }) : _vm._e(), acceptOrder.is_pay ? _c('van-cell', {
      attrs: {
        "title": "付款日期",
        "value": acceptOrder.pay_date
      }
    }) : _vm._e(), acceptOrder.is_pay ? _c('van-cell', {
      attrs: {
        "title": "付款方式",
        "value": _vm._f("payModeFilter")(acceptOrder.pay_mode)
      }
    }) : _vm._e(), acceptOrder.is_pay ? _c('van-cell', {
      attrs: {
        "title": "付款人",
        "value": acceptOrder.pay_employee_name
      }
    }) : _vm._e()], 1)]), _c('div', {
      staticClass: "btns"
    }, [_c('van-button', {
      attrs: {
        "square": "",
        "type": "primary",
        "size": "small"
      },
      on: {
        "click": function click($event) {
          return _vm.handleShowReceiptImage(acceptOrder);
        }
      }
    }, [_vm._v("查看单据")])], 1)]);
  }), 0)], 1), _c('div', {
    staticClass: "fixed-bottom-btn",
    on: {
      "click": _vm.handlePayment
    }
  }, [_c('van-button', {
    attrs: {
      "disabled": !_vm.paymentData.nums || _vm.paymentData.nums.length === 0,
      "block": "",
      "square": ""
    }
  }, [_vm._v("付款")])], 1), _c('my-dialog', {
    attrs: {
      "title": "确认付款"
    },
    on: {
      "confirm": _vm.handlePaymentConfirm
    },
    model: {
      value: _vm.showPaymentDialog,
      callback: function callback($$v) {
        _vm.showPaymentDialog = $$v;
      },
      expression: "showPaymentDialog"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "未付总额",
      "value-class": "cell-value",
      "value": '￥' + _vm.chooseAmount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "转成欠条",
      "center": ""
    }
  }, [_c('van-switch', {
    attrs: {
      "size": "20px"
    },
    model: {
      value: _vm.paymentData.to_iou,
      callback: function callback($$v) {
        _vm.$set(_vm.paymentData, "to_iou", $$v);
      },
      expression: "paymentData.to_iou"
    }
  })], 1), _c('van-cell', {
    attrs: {
      "title": "付款方式",
      "value-class": "pay-mode-value",
      "center": ""
    }
  }, [_c('van-radio-group', {
    attrs: {
      "direction": "horizontal"
    },
    model: {
      value: _vm.paymentData.pay_mode,
      callback: function callback($$v) {
        _vm.$set(_vm.paymentData, "pay_mode", $$v);
      },
      expression: "paymentData.pay_mode"
    }
  }, [_c('van-radio', {
    staticClass: "pay-radio",
    attrs: {
      "icon-size": "16px",
      "name": 1
    }
  }, [_vm._v("现金")]), _c('van-radio', {
    staticClass: "pay-radio",
    attrs: {
      "icon-size": "16px",
      "name": 2
    }
  }, [_vm._v("微信")]), _c('van-radio', {
    staticClass: "pay-radio",
    attrs: {
      "icon-size": "16px",
      "name": 3
    }
  }, [_vm._v("转账")])], 1)], 1), _c('van-cell', {
    attrs: {
      "title": "付款日期",
      "value": _vm.paymentData.pay_date,
      "center": "",
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleChooseDate('payment');
      }
    }
  }), _vm.paymentData.to_iou ? _c('van-field', {
    attrs: {
      "label": "实付金额",
      "type": "number",
      "placeholder": "请输入实付金额"
    },
    model: {
      value: _vm.paymentData.real_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.paymentData, "real_amount", $$v);
      },
      expression: "paymentData.real_amount"
    }
  }) : _vm._e()], 1), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择年月日"
    },
    on: {
      "confirm": _vm.handleDateConfirm,
      "cancel": function cancel($event) {
        _vm.show = false;
      }
    },
    model: {
      value: _vm.pickerDate,
      callback: function callback($$v) {
        _vm.pickerDate = $$v;
      },
      expression: "pickerDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }