<template>
  <div>
    <my-nav-bar title="未付明细" left-text="返回" left-arrow :right-text="hideFilterView ? '展开窗口' : '折叠窗口'" @click-right="handleHideFilterView" />
    <div class="fixed-container" :style="hideFilterView ? 'display: none' : 'display: block'">
      <van-cell
        title="开始日期"
        :value="listQuery.begin_date"
        is-link
        center
        @click="handleChooseDate('begin')"
      />
      <van-cell
        title="结束日期"
        :value="listQuery.end_date"
        is-link
        center
        @click="handleChooseDate('end')"
      />
      <van-cell
        title="来货金额"
        value-class="cell-value"
        :value="'￥' + totalAmount"
        center
      />
      <van-cell
        title="退货金额"
        value-class="cell-value"
        :value="'￥' + totalReturnAmount"
        center
      />
      <van-cell
        title="未付金额(含退货)"
        value-class="cell-value"
        :value="'￥' + totalNopayAmount"
        center
      />
    </div>
    <van-list
      ref="list"
      v-model="loading"
      :class="'list' + (hideFilterView ? ' hide-filter' : '')"
      :finished="finished"
      @load="getList"
    >
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-checkbox-group v-model="paymentData.nums">
        <div
          v-for="(acceptOrder, index) in list"
          :key="acceptOrder.id"
          class="cell-container"
        >
          <div class="cell" @click="handleCellCheck(index)">
            <div v-if="!acceptOrder.is_pay" class="cell-check">
              <van-checkbox :ref="'checkbox'" :name="acceptOrder.num" />
            </div>
            <div class="cell-content">
              <van-cell title="供应商" :value="acceptOrder.supplier.name" />
              <van-cell title="门店" :value="acceptOrder.store.name" />
              <van-cell title="制单人" :value="acceptOrder.creator.name" />
              <van-cell
                :title="acceptOrder.type === 1 ? '入库金额' : '退货金额'"
                :value="'￥' + acceptOrder.amount"
                value-class="cell-value"
              />
              <van-cell :title="acceptOrder.type === 1 ? '入库日期' : '退货日期'" :value="acceptOrder.date" />
              <van-cell
                v-if="acceptOrder.is_pay"
                title="操作时间"
                :value="acceptOrder.pay_datetime"
              />
              <van-cell
                v-if="acceptOrder.is_pay"
                title="付款日期"
                :value="acceptOrder.pay_date"
              />
              <van-cell
                v-if="acceptOrder.is_pay"
                title="付款方式"
                :value="acceptOrder.pay_mode | payModeFilter"
              />
              <van-cell
                v-if="acceptOrder.is_pay"
                title="付款人"
                :value="acceptOrder.pay_employee_name"
              />
            </div>
          </div>
          <div class="btns">
            <van-button
              square
              type="primary"
              size="small"
              @click="handleShowReceiptImage(acceptOrder)"
            >查看单据</van-button>
            <!-- <van-button v-if="acceptOrder.is_pay === 0" square type="primary" size="small" @click="handlePayment(acceptOrder)">付款</van-button> -->
          </div>
        </div>
      </van-checkbox-group>
    </van-list>
    <div class="fixed-bottom-btn" @click="handlePayment">
      <van-button :disabled="!paymentData.nums || paymentData.nums.length === 0" block square>付款</van-button>
    </div>
    <my-dialog v-model="showPaymentDialog" title="确认付款" @confirm="handlePaymentConfirm">
      <van-cell title="未付总额" value-class="cell-value" :value="'￥' + chooseAmount" />
      <van-cell title="转成欠条" center>
        <van-switch v-model="paymentData.to_iou" size="20px" />
      </van-cell>
      <van-cell title="付款方式" value-class="pay-mode-value" center>
        <van-radio-group v-model="paymentData.pay_mode" direction="horizontal">
          <van-radio class="pay-radio" icon-size="16px" :name="1">现金</van-radio>
          <van-radio class="pay-radio" icon-size="16px" :name="2">微信</van-radio>
          <van-radio class="pay-radio" icon-size="16px" :name="3">转账</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell
        title="付款日期"
        :value="paymentData.pay_date"
        center
        is-link
        @click="handleChooseDate('payment')"
      />
      <van-field
        v-if="paymentData.to_iou"
        v-model="paymentData.real_amount"
        label="实付金额"
        type="number"
        placeholder="请输入实付金额"
      />
    </my-dialog>
    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="pickerDate"
        type="date"
        title="选择年月日"
        @confirm="handleDateConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getSupplierPayments, payment } from '@/api/supplier-unpaid'
import { ImagePreview } from 'vant'
import { toDateString } from '@/utils'
import MyDialog from '@/components/my-dialog'
const payModeMaps = {
  1: '现金',
  2: '微信',
  3: '转账'
}
export default {
  components: { myNavBar, MyDialog },
  filters: {
    payModeFilter(payMode) {
      return payModeMaps[payMode]
    }
  },
  data() {
    return {
      list: [],
      listQuery: {
        page: 0,
        limit: 10,
        begin_date: '',
        end_date: ''
      },
      totalAmount: 0,
      totalReturnAmount: 0,
      totalNopayAmount: 0,
      pickerDate: new Date(),
      loading: false,
      finished: false,
      total: 0,
      showEmpty: false,
      show: false,
      dateType: '',
      showPaymentDialog: false,
      paymentData: {},
      chooseAmount: 0,
      hideFilterView: true
    }
  },
  created() {
    this.initPaymentData()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getSupplierPayments(this.$route.query.supplier_id, this.listQuery).then(
        (res) => {
          this.list = this.list.concat(res.data.list)
          this.loading = false
          this.finished = res.data.list.length < this.listQuery.limit
          this.showEmpty = this.list.length === 0
          this.total = res.data.total
          this.totalAmount = res.data.total_amount
          this.totalReturnAmount = res.data.total_return_amount
          this.totalNopayAmount = res.data.total_nopay_amount
        }
      )
    },
    previewImage(image) {
      ImagePreview([image])
    },
    reload() {
      this.list = []
      this.listQuery.page = 0
      this.loading = false
      this.finished = false
      this.showEmpty = false
      this.$refs.list.check()
      this.initPaymentData()
    },
    initPaymentData() {
      this.paymentData = {
        to_iou: false,
        pay_mode: 2,
        pay_date: '',
        real_amount: ''
      }
    },
    handleChooseDate(type) {
      this.dateType = type
      this.show = true
    },
    handleDateConfirm(date) {
      date = toDateString(date)
      if (this.dateType === 'begin') {
        this.listQuery.begin_date = date
        this.reload()
      } else if (this.dateType === 'end') {
        this.listQuery.end_date = date
        this.reload()
      } else {
        this.paymentData.pay_date = date
      }
      this.show = false
    },
    handlePayment() {
      this.showPaymentDialog = true
      this.chooseAmount = 0
      this.list.forEach(acceptOrder => {
        if (this.paymentData.nums.findIndex(num => acceptOrder.num === num) !== -1) {
          this.chooseAmount = (Number(this.chooseAmount) + Number(acceptOrder.amount)).toFixed(2)
        }
      })
    },
    handleShowReceiptImage(acceptOrder) {
      if (!acceptOrder.receipt_image) {
        this.fail('没有单据')
        return
      }
      ImagePreview([acceptOrder.receipt_image])
    },
    handleCellCheck(index) {
      const checkbox = this.$refs['checkbox'] ? this.$refs['checkbox'][index] : null
      if (!checkbox) { return }
      checkbox.toggle()
    },
    handlePaymentConfirm() {
      this.beginLoad()
      const data = Object.assign({}, this.paymentData)
      data.to_iou = Number(data.to_iou)
      data.supplier_id = this.$route.query.supplier_id
      payment(data).then(res => {
        this.endLoad()
        this.success(res.msg)
        this.reload()
      })
    },
    handleHideFilterView() {
      this.hideFilterView = !this.hideFilterView
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color.scss";
.cell-container {
  background-color: #fff;
  margin-bottom: 10px;
}
.cell {
  display: flex;
  align-items: center;
}
.cell-check .van-checkbox {
  padding: 10px;
}
.cell-content {
  width: 100%;
}
.fixed-container {
  position: fixed;
  top: 46px;
  left: 0;
  right: 0;
  z-index: 9;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #323232;
}
.cell-value {
  color: $amountRedColor;
}
.list {
  padding-top: 277px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 50px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
}
.footer {
  font-size: 12px;
  color: $grayTextColor;
}
.receipt {
  display: flex;
  margin-top: 5px;
  width: 100%;
}
.btns {
  display: flex;
  .van-button {
    flex: 1;
  }
}
.pay-mode-value {
  flex: 2;
}
.pay-radio {
  font-size: 13px;
}
.hide-filter {
  padding-top: 56px;
}
</style>
